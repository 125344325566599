import React, { useMemo, useState } from "react";
import { motion } from "framer-motion";
import { Box } from "@mui/material";
import PageHeader from "../components/pageUI/PageHeader";
import StackRow from "../components/layoutUI/StackRow";
import CustomButton from "../components/formUI/CustomButton";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ServerPaginationDataGrid from "../components/pageUI/ServerPaginationDataGrid";
import { useSelector } from "react-redux";
import DateRangePicker from "../components/pageUI/filters/DateRangePicker";
import axios from "axios";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import Text from "../components/typographyUI/Text";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";

const MessUserWiseReport = () => {
  const [loading, setLoading] = useState(false);


  // const { outletId } = useSelector((state) => state.auth.user);
  const outletId = useSelector(
    (state) => state.auth.user.messId || state.outledId.outletId
  );

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);

  const formattedStartDate = `${currentYear}-${String(currentMonth).padStart(
    2,
    "0"
  )}-01`;
  const formattedEndDate = currentDate.toISOString().split("T")[0];

  const [startDate, setStartDate] = useState(formattedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);

  const apiFormatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return !dateString ? "" : `${year}/${month}/${day}`;
  };

  const handleGenerateExcel = async() => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/getUserwiseReport/${outletId}?startDateTime=${startDate}&endDateTime=${endDate}`);
      
      if(response?.data?.success){
        const data = response?.data?.data;
        
        // Create workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Users");
  
        // Define headers
        const headers = [
          "Email",
          "Mobile",
          "Customer Name",
          "RSVP Count",
          "Actual Count",
          "Difference"
        ];
  
        // Add headers
        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true };
        });
  
        // Transform and add data
        data.forEach((item) => {
          const customer = item?.Customer;
  
          // Helper function to format counts
          const formatCounts = (countObj) => {
            return [
              `Breakfast: ${countObj[1] || 0}`,
              `Lunch: ${countObj[2] || 0}`,
              `HighTea: ${countObj[3] || 0}`,
              `Dinner: ${countObj[4] || 0}`
            ].join("\n");
          };
  
          // Helper function to format and color differences
          const formatDifference = (diffObj) => {
            const differenceDetails = [
              `Breakfast: ${diffObj[1] || 0}`,
              `Lunch: ${diffObj[2] || 0}`,
              `HighTea: ${diffObj[3] || 0}`,
              `Dinner: ${diffObj[4] || 0}`
            ];
  
            const richTextParts = differenceDetails.map((text) => {
              const value = parseInt(text.split(": ")[1]);
              if (value < 0) {
                return {
                  text: text + "\n",
                  font: { color: { argb: "FFFF0000" } } // Red
                };
              } else if (value > 0) {
                return {
                  text: text + "\n",
                  font: { color: { argb: "FF008000" } } // Green
                };
              }
              return {
                text: text + "\n",
                font: { color: { argb: "FF000000" } } // Black
              };
            });
  
            return { richText: richTextParts };
          };
  
          // Create row
          const row = worksheet.addRow([
            customer?.email,
            customer?.mobile,
            customer?.customerName,
            formatCounts(customer?.rsvpCount),
            formatCounts(customer?.actualCount),
            formatDifference(customer?.difference)
          ]);
  
          // Apply formatting to count columns
          [4, 5, 6].forEach((colNumber) => {
            const cell = row.getCell(colNumber);
            cell.alignment = {
              vertical: "top",
              wrapText: true
            };
          });
        });
  
        // Adjust column widths
        worksheet.columns.forEach((column, index) => {
          if (index === 0) column.width = 30;  // Email
          else if (index === 1) column.width = 15;  // Mobile
          else if (index === 2) column.width = 20;  // Name
          else column.width = 25;  // Count columns
        });
  
        // Set row height for data rows
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            row.height = 90;
          }
        });
  
        // Generate and save file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, `UserReport_${new Date().toISOString().split('T')[0]}.xlsx`);
      }
    } catch (error) {
      console.log("Error while generating report ", error);
    }finally{
        setLoading(false);
    }
  };

 

  const columns = useMemo(() => [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Text bold>{row?.Customer?.customerName}</Text>
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Text bold>{row?.Customer?.email}</Text>
        </>
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Text bold>{row?.Customer?.mobile}</Text>
        </>
      ),
    },
    {
      field: "rsvpcount",
      headerName: "RSVP Count",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const counts = row?.Customer?.rsvpCount || {};
        const values = Object.values(counts).join(' | ');
        return <Text bold>{values}</Text>;
      },
    },
    {
      field: "actualcount",
      headerName: "Actual Count",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const counts = row?.Customer?.actualCount || {};
        const values = Object.values(counts).join(' | ');
        return <Text bold>{values}</Text>;
      },
    },
    {
      field: "difference",
      headerName: "Difference",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const counts = row?.Customer?.difference || {};
        const values = Object.values(counts).join(' | ');
        return <Text bold style={{color:values > 0 ? '#16A34A' : values < 0 ? '#DC2626' : 'inherit'}}>{values}</Text>
      },
    },
  ]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box>
        <PageHeader
          secondary
          title={"User Datewise Report"}
          subTitle={"View and download your users report"}
        />
        <StackRow
          gap={"10px"}
          between
          center
          marginBottom="10px"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {/* <CustomSearchTextField
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        /> */}

          <StackRow center gap="14px">
            <DateRangePicker
              setFrom={setStartDate}
              setTo={setEndDate}
              fromDate={startDate}
              toDate={endDate}
              defaultValue
            />

            <CustomButton
              textColor="#4F46E5"
              bgColor="#EEF2FF"
              variant="outlined"
              startIcon={<CloudDownloadOutlinedIcon />}
              disabled={!Boolean(startDate && endDate)}
              onClick={handleGenerateExcel}
            >
              {loading ? "Generating..." : "Generate Excel"}
            </CustomButton>
          </StackRow>
        </StackRow>

        {outletId && (
          <CustomDataGrid
            rowId="customerAuthUID"
            name="user-table"
            labelName="rsvp"
            height={"500px"}
            columns={columns}
            url={
                `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/getUserwiseReport/${outletId}?startDateTime=${startDate}&endDateTime=${endDate}`
            }
            noActions
          />
        )}
      </Box>
    </motion.div>
  );
};

export default MessUserWiseReport;
