import { Box, Button, Grid } from "@mui/material";
import React, { useMemo, useState,useEffect } from "react";
import PageHeader from "../components/pageUI/PageHeader";
import StackRow from "../components/layoutUI/StackRow";
import CustomSearchTextField from "../components/formUI/CustomSearchTextField";
import StatusFilter from "../components/pageUI/filters/StatusFilter";
import ItemCard from "../components/pageUI/ItemCard";
import Text from "../components/typographyUI/Text";
import CustomChip from "../components/pageUI/CustomChip";
import { useDispatch, useSelector } from "react-redux";
import { useGetApis } from "../hooks/useGetApis";
import moment from "moment";
import { Stack } from "@mui/system";
import { acceptOrder, rejectOrder } from "../Services/orderService";
import CustomButton from "../components/formUI/CustomButton";
import CustomLink from "../components/formUI/CustomLink";
import { OrderStatus } from "../components/pageUI/orders/OrderStatus";
import { useNavigate } from "react-router-dom";
import ServerPaginationDataGrid from "../components/pageUI/ServerPaginationDataGrid";
import DateRangePicker from "../components/pageUI/filters/DateRangePicker";
import { toast } from "react-toastify";
import axios from "axios";
import { setLoadingSate } from "../Redux Store/Slices/loaderSlice";
import CustomCheckbox from "../components/formUI/CustomCheckbox";
import DummyImage from "../assets/images/dummy-image.png";
import {
  useSmallScreen,
  useMediumScreen,
} from "../utils/mediaQueries";
import RSVPQrScane from "./RSVPQrScane";
import * as XLSX from 'xlsx';
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const MessOrder = () => {
  const isSmallScreen = useSmallScreen();
  const { user } = useSelector((state) => state.auth);

  const { getHistoryOrders, toggelVisibility } = useGetApis();
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortType, setSortType] = useState("");
  const [scannedData, setScannedData] = useState("");
  const [canScan, setCanScan] = useState(true);
  const {serveMeal } = useGetApis();


  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [outletId, setOutletId] = useState(user?.outletId);
  const outletIdNew = useSelector((state) => state.auth.user.messId || state.outledId.outletId);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const isAuthId = !!user?.outletStaffData?.outletStaffAuthUId;

  const [chipColor] = useState({
    accepted: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    pending: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    pickUp: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    reject: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    dineIn: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    ord: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    foodready: {
      backgroundColor: "#FDF5FF",
      textColor: "#7E22CE",
    },
    delivered: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    dispatch: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    restaurants: {
      backgroundColor: "#F1F5F9",
      textColor: "#1347CC",
    },
  });

  const orderDetails = (orderId) => {
    navigate(`/orders/${orderId}`);
  };
  const handleAccept = (orderId) => {
    acceptOrder(orderId)
      .then((res) => {})
      .catch((error) => {});
  };
  const handleReject = (orderId) => {
    rejectOrder(orderId)
      .then((res) => {})
      .catch((error) => {});
  };


  const handleCheckboxChange = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };



  const handleFlagClick = async () => {
    // console.log("selectedOrders ", selectedOrders);

    dispatch(setLoadingSate(true));

    try {
      const response = await axios.post(toggelVisibility, {
        orderIdArray: selectedOrders,
      });

      if (response && response?.data?.success === true) {
        // console.log("response data ", response.data);

        toast.success("Order Ommitted");
      }
    } catch (error) {
      console.log("Error while setting flag ", error.message);
    }finally{
      dispatch(setLoadingSate(false));
    }
  };

  // const handleRemoveFlag = async () => {
  //   try {
  //     const data = await axios.get(removeAllFlag);
  //     console.log("dataaa ", data);

  //     if(data){
  //       console.log("dataaa ", data);
  //       toast.success("All Order Counted");
  //     }

        
      
  //   } catch (error) {
  //     console.log("Error while set flag ", error.message);
  //   }
  // };



  //kot print

  const handlePrint = (order) => {
    var content = document.createElement("div");
    content.innerHTML = getHPrintHTML(order);

    var pri = document.getElementById("ifmcontentstoprint")?.contentWindow;

    pri?.document?.open();
    pri?.document?.write(content.innerHTML);
    pri?.document?.write(`
    <style type="text/css">
      @media print {
        @page {
            margin-top: 0;
            margin-bottom: 0;
        }
        body {
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0 !important;
        }
      }
    </style>
    `);

    pri?.document?.close();
    pri?.focus();
    pri?.print();
  };
  function getHPrintHTML(order) {
    console.log("order ", order);
    const itemsHTML = order?.menu_item?.map(
      (item) =>
        `<div>
          <div style="float:left; margin-bottom:1px;">
          ${
            order?.additional_instruction
              ? "[note] : " + order?.additional_instruction
              : ""
          }
          </div><br/>
          <div style="float:left; font-weight: bold;">${item?.itemname} ${
          item?.variation?.variation_name
            ? ` ${item?.variation?.variation_name}`
            : ""
        } x ${item?.quantity}  
          </div>
          <div style="float:right; font-weight: bold;">
            ₹${
              item?.variation?.variation_price
                ? item?.variation?.variation_price
                : item?.calculated_price
            }
          </div>
          <div style="clear:both"></div>
           ${
             item?.addons?.length
               ? item?.addons
                   ?.map(
                     (addon) =>
                       `<div>
                    <div style="float:left;">${addon?.name}</div>
                    <div style="float:right;">₹${addon?.price}</div>
                    <div style="clear:both"></div>
                  </div>`
                   )
                   .join("")
               : ""
           }
        </div>`
    );

    const _html = `
      <div style="border:1px solid lightgray; height:auto; width: 65mm; margin:auto; padding:5px">
        <div style="text-align:center; font-size:18px; font-weight:600">${
          user?.outletName
        }</div>
        <div style="text-align:center">${user?.GSTIN ? "GSTIN: " + user?.GSTIN : ""}</div>
        <div style="text-align:center">${user?.FSSAI_number ? "FSSAI No:" + user?.FSSAI_number : ""}</div>
        <div style="text-align:center">${user?.address}</div>
        <div style="text-align:center">Mobile: + 91 ${user?.mobile}</div>
        <div style="text-align:center">Email: ${user?.email}</div>
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
    
        <div>
          <div style="float:left">${order?.order_schedule_date}</div>
          <div style="float:right">${convertTo12HourFormat(
            order?.order_schedule_time,
            order?.preparation_time,
            order?.schedule_now
          )}</div>
          <div style="clear:both"></div>
        </div>
        <div style="clear: both;border-bottom: 1px dotted black; margin-block:5px"></div>
          <div>${order?.customer_name}</div>
        <div>Order ID: #${order?.order_sequence_id}</div>
        
        <div style="margin-top:30px;">
          ${itemsHTML?.join("")}
          <div style="clear:both"></div>
        </div>
         
        <div style="clear:both; border-bottom: 1px dotted black; margin-block:5px"></div>
        
        <div>
          <div>
            <div style="float:left">Subtotal:</div>
            <div style="float:right">₹ ${order?.base_price}</div>
          </div>
          ${
            order?.is_delivery || order?.is_pick_up
              ? `<div style="clear:both">
                   <div style="float:left">Packaging Charges:</div>
                   <div style="float:right">₹ ${
                     order?.packaging_charge || 0
                   }</div>
                 </div>`
              : ""
          }
          ${
            order?.is_delivery && !order?.is_pick_up
              ? `<div style="clear: both">
                   <div style="float:left">Delivery Charge:</div>
                   <div style="float:right">₹ ${
                     order?.deliverycharge || 0
                   }</div>
                 </div>`
              : ""
          }
          <div style="display: ${
            order?.isgstapplied ? `block` : `none`
          }; clear:both">
            <div style="float:left">Taxes:</div>
            <div style="float:right">₹ ${order?.food_gst || 0}</div>
          </div>
          <div style="clear: both">
            <div style="float:left">Convenience Fee:</div>
            <div style="float:right">₹ ${
              order?.convenience_total_amount?.toFixed(2) || 0
            }</div>
          </div>
          <div style="clear:both"></div>
        </div>
        
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
        
        <div>
          <div style="float:left">Total:</div>
          <div style="float:right">₹ ${order?.amount?.toFixed(2)}</div>
          <div style="clear:both"></div>
        </div>
        
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
      </div>
    `;

    return _html;
  }
  function convertTo12HourFormat(time, preparationTime, addTime) {
    // Remove seconds
    time = time.split(":").slice(0, 2).join(":");

    // Convert time to total minutes
    let [hours, minutes] = time.split(":").map(Number);
    let totalMinutes = hours * 60 + minutes;

    // Adjust time based on the boolean value
    if (addTime) {
      totalMinutes += preparationTime + 15;
    }

    // Calculate the new time
    let newHours = Math.floor(totalMinutes / 60) % 24;
    let newMinutes = totalMinutes % 60;

    // Determine AM or PM
    let period = newHours >= 12 ? "PM" : "AM";

    // Adjust hours for 12-hour format
    if (newHours > 12) {
      newHours -= 12;
    } else if (newHours === 0) {
      newHours = 12;
    }

    // Format the time in 12-hour format
    let time12Hour = `${newHours}:${
      newMinutes < 10 ? "0" : ""
    }${newMinutes} ${period}`;

    return time12Hour;
  }

  const columns = useMemo(() => [
    {
      field: "order_sequence_id",
      headerName: "Order ID",
      flex: 1,
      minWidth: 90,
      maxWidth: 100,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.order_sequence_id}</Text>,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            borderRadius: "50%",
          }}
          img={row?.photo}
          title={row?.customer_name}
          subTitle={`+ ${row?.mobile}`}
          center
          sx={{
            height: 36,
          }}
        />
      ),
    },
    {
      field: "order_type",
      headerName: "Order Type",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomChip
          label={
            row?.is_dine_in
              ? "Dine In"
              : row?.is_pick_up
              ? "Pick Up"
              : row?.is_delivery
              ? "Delivery"
              : "Unknown"
          }
          textColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.textColor
          }
          backgroundColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.backgroundColor
          }
        />
      ),
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <CustomChip
    //       label={row.orderId.orderStatusId.text}
    //       textColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]?.textColor
    //       }
    //       backgroundColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]
    //           ?.backgroundColor
    //       }
    //     />
    //   ),
    // },
    {
      field: "order_value",
      headerName: "Order Value",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {" "}
          <Text bold>₹ {(row?.total_price).toFixed(2)}</Text>
        </>
      ),
    },
    {
      field: "kot",
      headerName: "Print KOT",
      flex: 1,
      minWidth: 110,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <CustomButton
            onClick={() => handlePrint(row)}
            textContent={"Print KOT"}
            sx={{
              fontWeight: "400",
              zoom: "0.8",
            }}
          />
        );
      },
    },
    {
      field: "Delivery time",
      headerName: "Delivery Time",
      flex: 1,
      minWidth: 180,
      sortable: false,
      // renderCell: ({ row }) => {
      //   const parsedTime = moment(row?.order_schedule_time, "HH:mm:ss");

      //   // Format the parsed time in the 12-hour format
      //   const formattedTime = parsedTime.format("h:mm A");

      //   const parsedDate2 = moment(row?.order_schedule_date, "YYYY-MM-DD");

      //   // Format the parsed date in the "YYYY-MM-DD" format
      //   const formattedDate2 = parsedDate2.format("ddd, DD MMM YYYY");
      //   return (
      //     // <CustomSwitch
      //     //   value={row.status}
      //     //   label="In Stock"
      //     //   onChange={(e) => {
      //     //
      //     //     // mutatePut({ ...row, status: e });
      //     //   }}
      //     // />
      //     <Stack>
      //       <Text bold>{formattedDate2}</Text>
      //       <Text bold>{formattedTime}</Text>
      //     </Stack>
      //   );
      // },
      renderCell: ({ row }) => {
        const parsedTime = moment(row?.order_schedule_time, "HH:mm:ss");
        const formattedTime = parsedTime.format("h:mm A");

        const parsedDate2 = moment(row?.order_schedule_date, "YYYY-MM-DD");
        let formattedAdjustedTime;

        if (row?.schedule_now) {
          const preparation_time = row?.preparation_time || 0;
          const adjustedTime = parsedTime.add(preparation_time + 15, "minutes");

          // Check if the adjusted time is past 12 AM
          // if (adjustedTime.isAfter(moment('12:00 AM', "h:mm A"))) {
          //   parsedDate2.add(1, 'day'); // Add 1 day to the date
          // }

          formattedAdjustedTime = adjustedTime.format("h:mm A");
        }

        const formattedDate2 = parsedDate2.format("ddd, DD MMM YYYY");

        return (
          <Stack>
            <Text bold>{formattedDate2}</Text>
            <Text bold>
              {row?.schedule_now ? formattedAdjustedTime : formattedTime}
            </Text>
          </Stack>
        );
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <CustomSwitch
    //       value={row.status}
    //       label="In Stock"
    //       onChange={(e) => {
    //
    //         // mutatePut({ ...row, status: e });
    //       }}
    //     />
    //   ),
    // },
    {
      field: "actions",
      headerName: "Order Status",
      flex: 1,
      minWidth: 160,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <OrderStatus
            row={row}
            chipColor={chipColor}
            handleAccept={handleAccept}
            handleReject={handleReject}
            noAction
            tab={"History"}
          />
        );
      },
    },
    {
      field: "view",
      headerName: "Action",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <CustomLink
            onClick={() => orderDetails(row?.order_id)}
            sx={{
              cursor: "pointer",
            }}
          >
            View Details
          </CustomLink>
        );
      },
    },
  ]);

  const refreshOrderData = () => {
    // Increment refresh key to force table reload
    setRefreshKey(prevKey => prevKey + 1);
    
    // Reset any filters or search parameters if needed
    setSearch("");
    setStartDate("");
    setEndDate("");
    setSortType("");
    setType("");
    setStatus("");
    
    // Clear selected orders
    setSelectedOrders([]);
  };

  useEffect(() => {
    let inputBuffer = "";
  
    const handleKeyPress = (event) => {
      // Only process input if scanning is allowed
      if (canScan) {
        // Assuming the scanner appends an Enter key (keyCode 13) at the end of a scan
        if (event.key === "Enter" && inputBuffer?.length > 3) {
          setScannedData(inputBuffer);
          sendScannedData(inputBuffer);
          refreshOrderData();
          
          // Disable scanning for 3 seconds
          setCanScan(false);
          setTimeout(() => {
            setCanScan(true);
          }, 3000);
  
          // Clear input buffer
          setTimeout(() => {
            inputBuffer = "";
          }, 100);
        } else {
          inputBuffer += event.key;
        }
      }
    };
  
    window.addEventListener("keypress", handleKeyPress);
  
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [canScan]); // Add canScan to dependencies

  const sendScannedData = async (data) => {
    // console.log("Qr code data ", data);

    if (data) {
      // console.log("data ", data);
      if (data?.includes("===")) {
        const menuId = data?.split("===")?.[0];
        const customerAuthUID = data?.split("===")?.[1];

        // console.log("menuid ", menuId);
        // console.log("customerId ", customerAuthUID);

        axios
          .post(`${serveMeal}`, {
            menuId,
            customerAuthUID,
            outletId: outletId,
          })
          .then((res) => {
            // console.log("QR scan response ", res);
            if (res?.data?.success) {
              // toast.success(res?.data?.message, { autoClose: 1000 });
              toast.success(
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // padding: "2rem",
                    }}
                  >
                    <img
                      src={DummyImage}
                      alt="Profile"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                    <div>
                      <Text bold style={{ fontSize: "1.8rem" }}>
                        {res?.data?.data?.Customer?.customerName}
                      </Text>
                      <Text bold style={{ fontSize: "1.1rem" }}>
                        {res?.data?.data?.Customer?.email}
                      </Text>
                    </div>
                  </div>
                  <Text
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#D1FFBD",
                      color: "darkgreen",
                      padding: "0.5rem",
                      borderRadius: "20px",
                      marginTop: "2rem",
                      textAlign: "center",
                    }}
                  >
                    Meal Served Successfully
                  </Text>
                </>,
                { autoClose: 3000 }
              );
              refreshOrderData();
            } else {
              toast.error(res?.data?.message, { autoClose: 1000 });
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.error, { autoClose: 1000 });
          });
      } else {
        toast.error("Invalid QR code.", { autoClose: 1000 });
      }
    }
  };

 
  return (
    <Box p={0}>
      <Box 
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          marginBottom: '24px'
        }}
      >
        {/* Left side - Header */}
        <Box>
          <PageHeader
            secondary
            title="Mess Orders"
            subTitle={"Your history orders are here."}
            sx={{ marginBottom: 0 }} // Remove default margin if any
          />
        </Box>

        {/* Right side - Scanner */}
        <Box>
          <RSVPQrScane outletId={outletId} afterScanMethod={refreshOrderData} />
        </Box>
      </Box>
      {
        isSmallScreen ? (
          <Grid container spacing={2} sx={{ mb: 3 }}>
      {/* Search Bar - Full width on all screen sizes */}
      <Grid item xs={12}>
        <CustomSearchTextField
          placeholder="Search by Order ID"
          value={search}
          onChange={(e) => setSearch(e.target.value.replace(/[^0-9]/g, ""))}
          fullWidth
        />
      </Grid>

      {/* Date Range Picker - Full width on small screens, auto on larger screens */}
      <Grid item xs={12} md="auto">
        <DateRangePicker
          setFrom={setStartDate}
          setTo={setEndDate}
          fromDate={startDate}
          toDate={endDate}
          defaultValue={false}
        />
      </Grid>

      {/* Sort by Time Filter - Half width on small screens, auto on larger screens */}
      <Grid item xs={12} md="auto">
        <StatusFilter
          label="Sort by Time"
          menuList={[
            { id: 1, label: "Ascending" },
            { id: 2, label: "Descending" },
          ]}
          onClick={(label) => setSortType(label?.toLowerCase())}
          fullWidth
        />
      </Grid>

      {/* Order Type Filter - Half width on small screens, auto on larger screens */}
      <Grid item xs={12} md="auto">
        <StatusFilter
          label="Order Type"
          defaultValue={"All"}
          menuList={[
            { id: null, label: "All" },
            { id: 1, label: "Dining" },
            { id: 2, label: "Pickup" },
            { id: 3, label: "Delivery" },
          ]}
          onClick={(label) => setType(label)}
          fullWidth
        />
      </Grid>
    </Grid>
        ) : (<StackRow between mb={"24px"}>
        <CustomSearchTextField
          placeholder="Search by Order ID"
          value={search}
          onChange={(e) => setSearch(e.target.value.replace(/[^0-9]/g, ""))}
        />
       
        {/* {!isAuthId && (
          <button
            style={{
              backgroundColor: "#1347CC",
              color: "white",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleRemoveFlag}
          >
            Remove
          </button>
        )} */}

        <StackRow gap="20px">
          <DateRangePicker
            setFrom={setStartDate}
            setTo={setEndDate}
            fromDate={startDate}
            toDate={endDate}
            defaultValue={false}
          />
          <StatusFilter
            label="Sort by Time"
            menuList={[
              { id: 1, label: "Ascending" },
              { id: 2, label: "Descending" },
            ]}
            onClick={(label) => setSortType(label?.toLowerCase())}
          />
          <StatusFilter
            label="Order Type"
            defaultValue={"All"}
            menuList={[
              { id: null, label: "All" },
              { id: 1, label: "Dining" },
              { id: 2, label: "Pickup" },
              { id: 3, label: "Delivery" },
            ]}
            onClick={(label) => setType(label)}
          />
          {/* <StatusFilter
            menuList={[
              { id: 123, label: "Accepted" },
              { id: 0, label: "Pending" },
              { id: 4, label: "Dispatched" },
              { id: 5, label: "Food Ready" },
              { id: 10, label: "Delivered" },
              { id: -2, label: "Reject" },
              { id: -1, label: "Cancelled" },
            ]}
            onClick={(label, id) => {
              if (id === 123) {
                setStatus("1,2,3");
              } else {
                setStatus(id);
              }
            }}
          /> */}
        </StackRow>
      </StackRow>)
      }
      <ServerPaginationDataGrid
        key={refreshKey}
        name="order_history"
        id={"order_id"}
        url={getHistoryOrders + outletIdNew}
        columns={columns}
        labelName="Order History"
        noActions
        query={{
          orderType:
            type == "Dining"
              ? "dinein"
              : type === "Pickup"
              ? "pickup"
              : type === "Delivery"
              ? "delivery"
              : "",
          // orderStatusId: status,
          orderSequenceId: search,
        }}
        orderStatusId={status}
        startDate={startDate !== "Invalid Date" ? startDate : ""}
        endDate={endDate !== "Invalid Date" ? endDate : ""}
        sortType={sortType}
        isAuthId={isAuthId}
        setSelectedOrders={setSelectedOrders}
      />
       <iframe
        id="ifmcontentstoprint"
        style={{
          height: "0px",
          width: "0px",
          position: "absolute",
          visibility: "hidden",
        }}
      ></iframe>
    </Box>
  );
};

export default MessOrder;
