import { motion } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Skeleton,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  CircularProgress,
  Stack,
  Tabs,
  Tab,
  Badge,
} from "@mui/material";
import StackRow from "../components/layoutUI/StackRow";
import Headline2 from "../components/typographyUI/Headline2";
import Text from "../components/typographyUI/Text";
import DashboardCard from "../components/pageUI/dashboard/DashboardCard";
import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";
import PaperBox from "../components/layoutUI/PaperBox";
import Headline1 from "../components/typographyUI/Headline1";
import RSVPQrScane from "./RSVPQrScane";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useGetApis } from "../hooks/useGetApis";
import DateRangePicker from "../components/pageUI/filters/DateRangePicker";
import CustomButton from "../components/formUI/CustomButton";
import { toast } from "react-toastify";
import DummyImage from "../assets/images/dummy-image.png";
import CachedIcon from "@mui/icons-material/Cached";
import CustomDialog from "../components/layoutUI/CustomDialog";
import PageHeader from "../components/pageUI/PageHeader";
import {
  AddCircleOutline,
  Close,
  RemoveCircleOutlineRounded,
} from "@mui/icons-material";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";
import CustomSearchTextField from "../components/formUI/CustomSearchTextField";
import CustomMenu from "../components/layoutUI/CustomMenu";
import CustomSwitch from "../components/formUI/CustomSwitch";
import { useMediumScreen, useSmallScreen } from "../utils/mediaQueries";
import { setCheckIsMess } from "../Redux Store/Slices/duplicateOutlet";
import { useNavigate } from "react-router-dom";

const GreenLabel = ({ text }) => (
  <Text
    style={{
      backgroundColor: "#F0FDF4",
      color: "green",
      borderRadius: "5px",
      padding: "0.5rem",
      height: "1.3rem",
    }}
  >
    {text}
  </Text>
);

const MDashboard = () => {
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();
  const { user } = useSelector((state) => state.auth);
  const [dashData, setDashData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadgraph, setLoadGraph] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  const [filterType, setFilterType] = useState(1);
  const [qrOpen, setQrOpen] = useState(true);
  const [search, setSearch] = useState("");
  const [addedUsers, setAddedUsers] = useState(new Set());
  const [removedUsers, setRemovedUsers] = useState(new Set());
  const [removedActualUsers, setRemovedActualUsers] = useState(new Set());
  const [addedActualUsers, setAddedActualUsers] = useState(new Set());
  const [selectedTab, setSelectedTab] = useState(
    JSON.parse(localStorage.getItem("checkIsMess")) == true ? 1 : 0 || 0
  );
  const pendingOrders = useSelector(
    (state) => state.pendingOrders.pendingOrders
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDataModal, setUserDataModal] = useState(false);
  const [mealId, setMealId] = useState(null);
  const [mealType, setMealType] = useState("");
  const [mealData, setMealData] = useState([]);
  const isAuthId = !!user?.outletStaffData?.outletStaffAuthUId;
  const accessArray = user?.outletStaffData?.roleId?.access;
  const [canScan, setCanScan] = useState(true);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);

    if (newValue === 0) {
      dispatch(setCheckIsMess(false));
      navigate("/");
    } else {
      dispatch(setCheckIsMess(true));
      navigate("/mdashboard");
    }
  };

  // console.log("addeduser ",addedUsers);

  const [scannedData, setScannedData] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [thirdModalOpen, setThirdModalOpen] = useState(false);
  const [fourthModalOpen, setFourthModalOpen] = useState(false);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [outletId, setOutletId] = useState(
    useSelector((state) => state.auth.user.messId || state.auth.user.outletId)
  );
  const outletIdFromRedux = useSelector((state) => state.outledId?.outletId);

  useEffect(() => {
    if (outletIdFromRedux && selectedTab === 0) {
      setOutletId(outletIdFromRedux);
      // console.log("outlet id ",outletIdFromRedux);
    }
  }, [outletIdFromRedux]);
  const hasScanner = useSelector((state) => state?.auth?.user?.hasScanner);
  const loader = useSelector((state) => state?.loader?.isLoading);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/getRSVPCompulsion/${outletId}`
      )
      .then((response) => {
        // Assuming the API response contains a boolean status
        // console.log("status res ", response?.data?.data);
        if (response?.data?.success) {
          setChecked(response?.data?.data?.rsvpCompulsion);
        }
      })
      .catch((error) => {
        console.error("Error fetching the switch status:", error);
      });
  }, [outletId]);

  // Handle toggle event
  const handleToggle = () => {
    const newChecked = !checked;
    setChecked(newChecked);

    // Call the API with the new state
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/toggleRSVPCompulsion/${outletId}`,
        { rsvpCompulsion: newChecked }
      )
      .then((response) => {
        console.log("Switch status updated:", response);
        if (response?.data?.success) {
          if (newChecked) {
            toast.success("RSVP is compulsory");
          } else {
            toast.error("RSVP is not compulsory");
          }
        }
      })
      .catch((error) => {
        console.error("Error updating the switch status:", error);
      });
  };
  // console.log("outlet id ",outletId);

  // console.log({startDate,endDate})

  const [selectedPlate, setSelectedPlate] = useState(1);
  const [selectedRemovePlate, setSelectedRemovePlate] = useState(1);
  const [selectedActualPlate, setSelectedActualPlate] = useState(1);
  const [selectedRemoveActualPlate, setSelectedRemoveActualPlate] = useState(1);

  const { getMessDashboardData, serveMeal } = useGetApis();

  const handleStudentAdd = async (customerAuthUID) => {
    setAddLoader(true);
    try {
      const studentData = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/addManualRSVP`,
        {
          outletId: outletId,
          customerAuthUID,
          mealTypeId: selectedPlate,
        }
      );

      console.log("add user menu data response ", studentData);
      if (studentData?.data?.success) {
        setAddedUsers((prev) => new Set(prev).add(customerAuthUID));
        handleRefresh();
      } else {
        toast.error(studentData?.data?.message);
      }
    } catch (error) {
      console.log("Error while add menualy plates ");
    } finally {
      setAddLoader(false);
    }
  };

  const handleStudentRemove = async (customerAuthUID, rsvpId) => {
    setAddLoader(true);
    try {
      const studentData = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/removeRSVP/${rsvpId}`
      );

      console.log("remove user menu data response ", studentData);
      if (studentData?.data?.success) {
        setRemovedUsers((prev) => new Set(prev).add(customerAuthUID));
        handleRefresh();
      } else {
        toast.error(studentData?.data?.message);
      }
    } catch (error) {
      console.log("Error while add menualy plates ");
    } finally {
      setAddLoader(false);
    }
  };

  const handleStudentAddActual = async (customerAuthUID) => {
    setAddLoader(true);
    try {
      const studentData = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/addManualPlates`,
        {
          outletId: outletId,
          customerAuthUID,
          mealTypeId: selectedActualPlate,
        }
      );
      if (studentData?.data?.success) {
        setAddedActualUsers((prev) => new Set(prev).add(customerAuthUID));
        handleRefresh();
      } else {
        toast.error(studentData?.data?.message);
      }
    } catch (error) {
      console.log("Error while add menualy plates ");
    } finally {
      setAddLoader(false);
    }
  };

  const handleStudentRemoveActual = async (customerAuthUID, mealServedId) => {
    setAddLoader(true);
    try {
      const studentData = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/removeManualPlates/${mealServedId}`
      );
      if (studentData?.data?.success) {
        setRemovedActualUsers((prev) => new Set(prev).add(customerAuthUID));
        handleRefresh();
      } else {
        toast.error(studentData?.data?.message);
      }
    } catch (error) {
      console.log("Error while add menualy plates ");
    } finally {
      setAddLoader(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => (
          <Text bold>{row?.Customer?.customerName}</Text>
        ),
      },

      {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => <Text bold>{row?.Customer?.email}</Text>,
      },
      {
        field: "Add",
        headerName: "Add",
        flex: 0, // Remove flex to prevent stretching
        minWidth: 100,
        sortable: false,
        align: "right", // Align header to right
        headerAlign: "right", // Align header to right
        renderCell: ({ row }) => (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <CustomButton
              onClick={() => handleStudentAdd(row?.customerAuthUID)}
              loading={addLoader}
              disabled={addedUsers?.has(row?.customerAuthUID)}
            >
              {addedUsers?.has(row?.customerAuthUID) ? "Added" : "Add"}
            </CustomButton>
          </Box>
        ),
      },
    ],
    [addedUsers]
  );

  const columns1 = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => (
          <Text bold>{row?.Customer?.customerName}</Text>
        ),
      },

      {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => <Text bold>{row?.Customer?.email}</Text>,
      },
      {
        field: "Add",
        headerName: "Add",
        flex: 0,
        minWidth: 100,
        sortable: false,
        align: "right",
        headerAlign: "right",
        renderCell: ({ row }) => (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <CustomButton
              onClick={() =>
                handleStudentRemove(row?.customerAuthUID, row?.rsvpId)
              }
              loading={addLoader}
              disabled={removedUsers?.has(row?.customerAuthUID)}
            >
              {removedUsers?.has(row?.customerAuthUID) ? "Removed" : "Remove"}
            </CustomButton>
          </Box>
        ),
      },
    ],
    [removedUsers]
  );

  const columns3 = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => (
          <Text bold>{row?.Customer?.customerName}</Text>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 230,
        sortable: false,
        renderCell: ({ row }) => <Text bold>{row?.Customer?.email}</Text>,
      },
      {
        field: "mobile",
        headerName: "Mobile",
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => <Text bold>{row?.Customer?.mobile}</Text>,
      },
    ],
    [removedUsers]
  );

  const columns4 = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => (
          <Text bold>{row?.Customer?.customerName}</Text>
        ),
      },

      {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => <Text bold>{row?.Customer?.email}</Text>,
      },
      {
        field: "Add",
        headerName: "Add",
        flex: 0,
        minWidth: 100,
        sortable: false,
        align: "right",
        headerAlign: "right",
        renderCell: ({ row }) => (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <CustomButton
              onClick={() => handleStudentAddActual(row?.customerAuthUID)}
              loading={addLoader}
              disabled={addedActualUsers?.has(row?.customerAuthUID)}
            >
              {addedActualUsers?.has(row?.customerAuthUID) ? "Added" : "Add"}
            </CustomButton>
          </Box>
        ),
      },
    ],
    [addedActualUsers]
  );

  const columns5 = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => (
          <Text bold>{row?.Customer?.customerName}</Text>
        ),
      },

      {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => <Text bold>{row?.Customer?.email}</Text>,
      },
      {
        field: "Remove",
        headerName: "Remove",
        flex: 0,
        minWidth: 100,
        sortable: false,
        align: "right",
        headerAlign: "right",
        renderCell: ({ row }) => (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <CustomButton
              onClick={() =>
                handleStudentRemoveActual(
                  row?.customerAuthUID,
                  row?.mealServedId
                )
              }
              loading={addLoader}
              disabled={removedActualUsers?.has(row?.customerAuthUID)}
            >
              {removedActualUsers?.has(row?.customerAuthUID)
                ? "Removed"
                : "Remove"}
            </CustomButton>
          </Box>
        ),
      },
    ],
    [removedActualUsers]
  );

  useEffect(() => {
    let inputBuffer = "";
  
    const handleKeyPress = (event) => {
      // Only process input if scanning is allowed
      if (canScan) {
        // Assuming the scanner appends an Enter key (keyCode 13) at the end of a scan
        if (event.key === "Enter" && inputBuffer?.length > 3) {
          setScannedData(inputBuffer);
          sendScannedData(inputBuffer);
          
          // Disable scanning for 3 seconds
          setCanScan(false);
          setTimeout(() => {
            setCanScan(true);
          }, 3000);
  
          // Clear input buffer
          setTimeout(() => {
            inputBuffer = "";
          }, 100);
        } else {
          inputBuffer += event.key;
        }
      }
    };
  
    window.addEventListener("keypress", handleKeyPress);
  
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [canScan]); // Add canScan to dependencies

  const sendScannedData = async (data) => {
    // console.log("Qr code data ", data);

    if (data) {
      // console.log("data ", data);
      if (data?.includes("===")) {
        const menuId = data?.split("===")?.[0];
        const customerAuthUID = data?.split("===")?.[1];

        // console.log("menuid ", menuId);
        // console.log("customerId ", customerAuthUID);

        axios
          .post(`${serveMeal}`, {
            menuId,
            customerAuthUID,
            outletId: outletId,
          })
          .then((res) => {
            // console.log("QR scan response ", res);
            if (res?.data?.success) {
              // toast.success(res?.data?.message, { autoClose: 1000 });
              toast.success(
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // padding: "2rem",
                    }}
                  >
                    <img
                      src={DummyImage}
                      alt="Profile"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                    <div>
                      <Text bold style={{ fontSize: "1.8rem" }}>
                        {res?.data?.data?.Customer?.customerName}
                      </Text>
                      <Text bold style={{ fontSize: "1.1rem" }}>
                        {res?.data?.data?.Customer?.email}
                      </Text>
                    </div>
                  </div>
                  <Text
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#D1FFBD",
                      color: "darkgreen",
                      padding: "0.5rem",
                      borderRadius: "20px",
                      marginTop: "2rem",
                      textAlign: "center",
                    }}
                  >
                    Meal Served Successfully
                  </Text>
                </>,
                { autoClose: 3000 }
              );
              getDashboardData();
            } else {
              toast.error(res?.data?.message, { autoClose: 1000 });
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.error, { autoClose: 1000 });
          });
      } else {
        toast.error("Invalid QR code.", { autoClose: 1000 });
      }
    }
  };

  // useEffect(() => {
  //   outletId && getDashboardData();
  //   outletId && getGraphData();
  // }, [outletId]);

  const handleRefresh = () => {
    getDashboardData();
    getGraphData();
  };

  const [data, setData] = useState([
    {
      id: 1,
      name: "Breakfast RSVPs",
      amount: 999,
      tooltipText: "Total users vote yes for breakfast",
    },
    {
      id: 2,
      name: "Lunch RSVPs",
      amount: 999,
      tooltipText: "Total users vote yes for lunch",
    },
    {
      id: 3,
      name: "High Tea RSVPs",
      amount: 999,
      tooltipText: "Total users vote yes for high tea",
    },
    {
      id: 4,
      name: "Dinner RSVPs",
      amount: 999,
      tooltipText: "Total users vote yes for dinner",
    },
  ]);

  const [data1, setData1] = useState([
    {
      id: 1,
      name: "Breakfast Actual Plates",
      amount: 777,
      tooltipText: "Total users collect plate for breakfast",
    },
    {
      id: 2,
      name: "Lunch Actual Plates",
      amount: 999,
      tooltipText: "Total users collect plate for lunch",
    },
    {
      id: 3,
      name: "High Tea Actual Plates",
      amount: 999,
      tooltipText: "Total users collect plate for high tea",
    },
    {
      id: 4,
      name: "Dinner Actual Plates",
      amount: 999,
      tooltipText: "Total users collect plate for dinner",
    },
  ]);

  const [data2, setData2] = useState([
    {
      id: 1,
      name: "Avg. Plate Count - Breakfast",
      amount: 777,
    },
    {
      id: 2,
      name: "Avg. Plate Count - Lunch",
      amount: 999,
    },
    {
      id: 3,
      name: "Avg. Plate Count - Snack",
      amount: 999,
    },
    {
      id: 4,
      name: "Avg. Plate Count - Dinner",
      amount: 999,
    },
  ]);

  const [plateCount, setPlateCount] = useState([
    {
      id: "japan",
      color: "rgba(99, 102, 241, 1)",
      data: [
        { x: "1", y: 0 },
        { x: "2", y: 40 },
        { x: "3", y: 15 },
        { x: "4", y: 30 },
        { x: "5", y: 35 },
        { x: "6", y: 45 },
        { x: "7", y: 20 },
        { x: "8", y: 25 },
        { x: "9", y: 50 },
        { x: "10", y: 20 },
        { x: "11", y: 30 },
        { x: "12", y: 40 },
        { x: "13", y: 0 },
        { x: "14", y: 40 },
        { x: "15", y: 15 },
        { x: "16", y: 30 },
        { x: "17", y: 35 },
        { x: "18", y: 45 },
        { x: "19", y: 20 },
        { x: "20", y: 25 },
        { x: "21", y: 50 },
        { x: "22", y: 20 },
        { x: "23", y: 30 },
        { x: "24", y: 40 },
        { x: "25", y: 40 },
        { x: "26", y: 40 },
        { x: "27", y: 40 },
        { x: "28", y: 40 },
        { x: "29", y: 40 },
        { x: "30", y: 40 },
        { x: "31", y: 40 },
      ].map((item, index) => {
        const months = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ];

        return {
          ...item,
          key: index,
          x: months[index],
        };
      }),
    },
  ]);

  useEffect(() => {
    if (outletId) {
      getDashboardData();
      getGraphData();
    }
  }, [outletId, filterType, endDate, startDate]);

  const getDashboardData = async () => {
    setLoading(true);
    try {
      const dashboardData = await axios.get(
        `${getMessDashboardData}${outletId}?day=${filterType}&startDate=${startDate}&endDate=${endDate}`
      );
      // console.log("dashboard ", dashboardData);
      setDashData(dashboardData?.data?.data);
      const filteredData = [
        {
          id: 1,
          name: "Breakfast RSVPs",
          amount: dashboardData?.data?.data?.breakfastRSVP,
          growth: 50,
          tooltipText: "Total users vote yes for breakfast",
        },
        {
          id: 2,
          name: "Lunch RSVPs",
          amount: dashboardData?.data?.data?.lunchRSVP,
          growth: 50,
          tooltipText: "Total users vote yes for lunch",
        },
        {
          id: 3,
          name: "High Tea RSVPs",
          amount: dashboardData?.data?.data?.highTeaRSVP,
          growth: 50,
          tooltipText: "Total users vote yes for high tea",
        },
        {
          id: 4,
          name: "Dinner RSVPs",
          amount: dashboardData?.data?.data?.dinnerRSVP,
          growth: 50,
          tooltipText: "Total users vote yes for dinner",
        },
      ].filter((item) => item.amount !== -1); // Filter out the items where amount is -1
      setData(filteredData);

      const filteredData1 = [
        {
          id: 1,
          name: "Breakfast Actual Plates",
          amount: dashboardData?.data?.data?.breakfastCount,
          tooltipText: "Total users collect plate for breakfast",
        },
        {
          id: 2,
          name: "Lunch Actual Plates",
          amount: dashboardData?.data?.data?.lunchCount,
          tooltipText: "Total users collect plate for lunch",
        },
        {
          id: 3,
          name: "High Tea Actual Plates",
          amount: dashboardData?.data?.data?.highTeaCount,
          tooltipText: "Total users collect plate for high tea",
        },
        {
          id: 4,
          name: "Dinner Actual Plates",
          amount: dashboardData?.data?.data?.dinnerCount,
          tooltipText: "Total users collect plate for dinner",
        },
      ].filter((item) => item.amount !== -1);

      setData1(filteredData1);

      const filteredData2 = [
        {
          id: 1,
          name: "Avg. Plate Count - Breakfast",
          amount: dashboardData?.data?.data?.avBreakfastCount,
        },
        {
          id: 2,
          name: "Avg. Plate Count - Lunch",
          amount: dashboardData?.data?.data?.avLunchCount,
        },
        {
          id: 3,
          name: "Avg. Plate Count - Snack",
          amount: dashboardData?.data?.data?.avHighTeaCount,
        },
        {
          id: 4,
          name: "Avg. Plate Count - Dinner",
          amount: dashboardData?.data?.data?.avDinnerCount,
        },
      ].filter((item) => item.amount !== -1);

      setData2(filteredData2);
    } catch (error) {
      console.log("error while dashboard data fetch");
    } finally {
      setLoading(false);
    }
  };

  const getGraphData = async () => {
    setLoadGraph(true);
    try {
      const graphData = await axios.get(
        process.env.REACT_APP_BACKEND_BASE_URL +
          `mess/dashboard/getGraphData/${outletId}`
      );
      // console.log("graph ", graphData?.data?.data?.length);

      const dataLength = graphData?.data?.data?.length;

      setPlateCount([
        {
          id: "japan",
          color: "rgba(99, 102, 241, 1)",
          data: [
            { x: "1", y: `${graphData?.data?.data[0]?.count01}` },
            { x: "2", y: `${graphData?.data?.data[1]?.count02}` },
            { x: "3", y: `${graphData?.data?.data[2]?.count03}` },
            { x: "4", y: `${graphData?.data?.data[3]?.count04}` },
            { x: "5", y: `${graphData?.data?.data[4]?.count05}` },
            { x: "6", y: `${graphData?.data?.data[5]?.count06}` },
            { x: "7", y: `${graphData?.data?.data[6]?.count07}` },
            { x: "8", y: `${graphData?.data?.data[7]?.count08}` },
            { x: "9", y: `${graphData?.data?.data[8]?.count09}` },
            { x: "10", y: `${graphData?.data?.data[9]?.count10} ` },
            { x: "11", y: `${graphData?.data?.data[10]?.count11}` },
            { x: "12", y: `${graphData?.data?.data[11]?.count12}` },
            { x: "13", y: `${graphData?.data?.data[12]?.count13}` },
            { x: "14", y: `${graphData?.data?.data[13]?.count14}` },
            { x: "15", y: `${graphData?.data?.data[14]?.count15}` },
            { x: "16", y: `${graphData?.data?.data[15]?.count16}` },
            { x: "17", y: `${graphData?.data?.data[16]?.count17}` },
            { x: "18", y: `${graphData?.data?.data[17]?.count18}` },
            { x: "19", y: `${graphData?.data?.data[18]?.count19}` },
            { x: "20", y: `${graphData?.data?.data[19]?.count20}` },
            { x: "21", y: `${graphData?.data?.data[20]?.count21}` },
            { x: "22", y: `${graphData?.data?.data[21]?.count22}` },
            { x: "23", y: `${graphData?.data?.data[22]?.count23}` },
            { x: "24", y: `${graphData?.data?.data[23]?.count24}` },
            { x: "25", y: `${graphData?.data?.data[24]?.count25}` },
            { x: "26", y: `${graphData?.data?.data[25]?.count26}` },
            { x: "27", y: `${graphData?.data?.data[26]?.count27}` },
            { x: "28", y: `${graphData?.data?.data[27]?.count28}` },
            { x: "29", y: `${graphData?.data?.data[28]?.count29}` },
            { x: "30", y: `${graphData?.data?.data[29]?.count30}` },
            { x: "31", y: `${graphData?.data?.data[30]?.count31}` },
          ]
            ?.slice(0, dataLength)
            ?.map((item, index) => {
              const months = [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
              ];

              return {
                ...item,
                key: index,
                x: months[index],
              };
            }),
        },
      ]);
    } catch (error) {
      console.log("Error while fetch graph data");
    } finally {
      setLoadGraph(false);
    }
  };

  // const handleDataChange = (e) => {
  //   const data = e.target.value;

  //   console.log("laser scane data ",data);
  // }

  // const [isCameraActive, setIsCameraActive] = useState(false);
  // const videoRef = useRef(null);

  // const requestCameraAccess = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //     if (videoRef.current) {
  //       videoRef.current.srcObject = stream;
  //     }
  //     setIsCameraActive(true);
  //   } catch (err) {
  //     console.error('Error accessing the camera', err);
  //   }
  // };

  const handlePlateChange = (event) => {
    setSelectedPlate(event.target.value);
    setAddedUsers(null);
    // console.log("selected plates ", selectedPlate)
  };

  const handlePlateRemoveChange = (event) => {
    setSelectedRemovePlate(event.target.value);
    setRemovedUsers(null);
  };

  const handleActualPlateChange = (event) => {
    setSelectedActualPlate(event.target.value);
    setAddedActualUsers(null);
  };

  const handleActualPlateRemoveChange = (event) => {
    setSelectedRemoveActualPlate(event.target.value);
    setRemovedActualUsers(null);
  };

  const normalizeString = (str) =>
    str?.trim()?.toLowerCase()?.replace(/\s+/g, " ");
  const getFilterData = (data) => {
    let _data = data;
    if (search) {
      const searchTerm = normalizeString(search);
      _data = _data?.filter((row) =>
        normalizeString(row?.Customer?.customerName)?.includes(searchTerm)
      );
    }
    return _data;
  };

  const renderDateRangePicker = () => {
    return (
      <DateRangePicker
        setFrom={setStartDate}
        setTo={setEndDate}
        fromDate={startDate}
        toDate={endDate}
      />
    );
  };

  const handleCardClick = (id, type) => {
    setMealId(id);
    setMealType(type);
    setUserDataModal(true);
  };

  useEffect(() => {
    outletId && getMealData();
  }, [outletId]);

  const getMealData = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}mess/weeklyMenu/getEnabledMeals/${outletId}`
      );
      console.log("get menu res ", result);
      setMealData(result?.data?.data);
    } catch (error) {
      console.log("Error while fetching menu data");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {!!user?.messId && isSmallScreen && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #5468FF",
            borderRadius: "8px",
            marginBottom: "1rem",
            marginTop: "-2rem",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            sx={{
              minHeight: "28px", // Adjust the height of the TabBar
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .Mui-selected": {
                color: "#5468FF", // Custom selected tab color
                backgroundColor: "#E5EDFF", // Background for the selected tab
                // borderRadius: "8px",
              },
            }}
            TabIndicatorProps={{
              style: { display: "none" }, // Hide the indicator
            }}
          >
            <Tab
              label={
                <Box sx={{ position: "relative", display: "inline-block" }}>
                  <Badge
                    badgeContent={pendingOrders?.length}
                    color="primary"
                    sx={{
                      position: "absolute",
                      // top: "-8px",
                      left: "-12px",
                      zIndex: 1, // Ensures the badge stays on top
                    }}
                  />
                  Outlet
                </Box>
              }
              sx={{
                textTransform: "none",
                color: "#5468FF",
                fontWeight: "bold",
                minHeight: "28px", // Adjust the height of individual tabs
                padding: "0 16px", // Reduce padding for a smaller tab size
                borderRadius: "8px 0px 0px 8px",
                // borderRight: '1px solid #5468FF',
              }}
            />
            <Tab
              label="Mess"
              sx={{
                textTransform: "none",
                color: "#5468FF",
                fontWeight: "bold",
                minHeight: "28px", // Adjust the height of individual tabs
                padding: "0 16px", // Reduce padding for a smaller tab size
                borderRadius: "0px 8px 8px 0px",
                borderLeft: "1px solid #5468FF",
              }}
            />
          </Tabs>
        </Box>
      )}
      <Box
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Headline1>Live Dashboard</Headline1>
            <Text style={{ marginBottom: "1.2rem", fontSize: "1.3rem" }}>
              Welcome to MealPe
            </Text>
          </Box>
          <GreenLabel text={"New"} />
          <RSVPQrScane outletId={outletId} afterScanMethod={getDashboardData} />
        </div>

        {/* {
            hasScanner && <CustomButton onClick={requestCameraAccess}>Acess Scanner</CustomButton>
          } */}

        {/* <Box style={{ display: "relative" }}>
          <IconButton style={{display:"absolute",left:"72rem"}} onClick={handleRefresh}>
            <CachedIcon fontSize="large"/>
          </IconButton>
        </Box> */}
        <Box style={{ display: "flex", alignItems: "center" }}>
          {/* <IconButton onClick={handleRefresh}>
            <CachedIcon fontSize="large" />
          </IconButton> */}
          <CustomSwitch
            value={checked}
            onChange={handleToggle}
            // inputProps={{ "aria-label": "controlled" }}
          />
          <Text>
            {checked ? "RSVP is compulsory" : "RSVP is not compulsory"}
          </Text>
        </Box>
      </Box>

      <Box
        style={{
          display: isSmallScreen ? "" : "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginBottom: isSmallScreen ? "20px" : "0px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            marginTop: "1rem",
          }}
        >
          {/* <CustomButton variant="outlined" onClick={() => setModalOpen(true)}>
            + Actual Plates
          </CustomButton> */}
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <CustomButton
            variant={filterType == 2 ? "contained" : "outlined"}
            onClick={() => setFilterType(2)}
            style={{
              margin: "0 0.3rem",
              width: isSmallScreen ? "100%" : "auto",
            }}
          >
            Yesterday
          </CustomButton>
          <CustomButton
            variant={filterType == 1 ? "contained" : "outlined"}
            onClick={() => setFilterType(1)}
            style={{
              margin: "0 0.3rem",
              width: isSmallScreen ? "100%" : "auto",
            }}
          >
            Today
          </CustomButton>
          <CustomButton
            variant={filterType == 3 ? "contained" : "outlined"}
            onClick={() => setFilterType(3)}
            style={{
              margin: "0 0.5rem",
              width: isSmallScreen ? "100%" : "auto",
            }}
          >
            Tomorrow
          </CustomButton>

          {isSmallScreen ? null : renderDateRangePicker()}
          {!!isAuthId ? (
            <CustomMenu
              stepGuide
              className="menu"
              width="220px"
              menuList={[
                ...(accessArray?.includes("Add RSVPs")
                  ? [
                      {
                        id: 1,
                        label: "Add RSVPs",
                        className: "custom-menu-item-3",
                        icon: <AddCircleOutline />,
                      },
                    ]
                  : []),
                ...(accessArray?.includes("Remove RSVPs")
                  ? [
                      {
                        id: 2,
                        label: "Remove RSVPs",
                        className: "custom-menu-item-1",
                        icon: <RemoveCircleOutlineRounded />,
                      },
                    ]
                  : []),
                ...(accessArray?.includes("Add Platecount")
                  ? [
                      {
                        id: 3,
                        label: "Add Plate Count",
                        className: "custom-menu-item-1",
                        icon: <AddCircleOutline />,
                      },
                    ]
                  : []),
                ...(accessArray?.includes("Remove Platecount")
                  ? [
                      {
                        id: 4,
                        label: "Remove Plate Count",
                        className: "custom-menu-item-1",
                        icon: <RemoveCircleOutlineRounded />,
                      },
                    ]
                  : []),
              ].filter(Boolean)}
              menuOnClick={({ label, id: _id }) => {
                if (_id === 1) {
                  setModalOpen(true);
                } else if (_id === 2) {
                  setSecondModalOpen(true);
                } else if (_id === 3) {
                  setThirdModalOpen(true);
                } else {
                  setFourthModalOpen(true);
                }
              }}
            />
          ) : (
            <CustomMenu
              stepGuide
              className="menu"
              width={"220px"}
              menuList={[
                {
                  id: 1,
                  label: "Add RSVPs",
                  className: "custom-menu-item-3",
                  icon: <AddCircleOutline />,
                },
                {
                  id: 2,
                  label: "Remove RSVPs",
                  className: "custom-menu-item-1",
                  icon: <RemoveCircleOutlineRounded />,
                },
                {
                  id: 3,
                  label: "Add Plate Count",
                  className: "custom-menu-item-1",
                  icon: <AddCircleOutline />,
                },
                {
                  id: 4,
                  label: "Remove Plate Count",
                  className: "custom-menu-item-1",
                  icon: <RemoveCircleOutlineRounded />,
                },
              ]}
              // onClick={() => setCloseGuide(true)}
              menuOnClick={({ label, id: _id }) => {
                if (_id === 1) {
                  setModalOpen(true);
                } else if (_id === 2) {
                  setSecondModalOpen(true);
                } else if (_id === 3) {
                  setThirdModalOpen(true);
                } else {
                  setFourthModalOpen(true);
                }
              }}
            />
          )}
        </Box>
      </Box>
      {!isSmallScreen ? null : renderDateRangePicker()}

      {loading ? (
        <Grid
          container
          spacing={"1.7rem"}
          rowSpacing={"2.8rem"}
          columnSpacing={"1.7rem"}
          marginTop={"2rem"}
        >
          {" "}
          {new Array(4).fill(0).map((order, index) => {
            return (
              <>
                <Grid item xs={3} key={index}>
                  <PaperBox padding={"2.28rem"}>
                    <Skeleton
                      height={"1.5rem"}
                      width={"60%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 2,
                      }}
                      animation="wave"
                    />
                    <Skeleton
                      height={"2.5rem"}
                      width={"100%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                      }}
                      animation="wave"
                    />
                  </PaperBox>
                </Grid>
                <Grid item xs={3} key={index}>
                  <PaperBox padding={"2.28rem"}>
                    <Skeleton
                      height={"1.5rem"}
                      width={"60%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 2,
                      }}
                      animation="wave"
                    />
                    <Skeleton
                      height={"2.5rem"}
                      width={"100%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                      }}
                      animation="wave"
                    />
                  </PaperBox>
                </Grid>
              </>
            );
          })}
          <Grid item xs={12} md={12}>
            <PaperBox
              sx={{
                mb: 4,
              }}
            >
              <Box sx={{}}>
                <Skeleton
                  height={"1.5rem"}
                  width={"10%"}
                  sx={{
                    transform: "scale(1)",
                    transformOrigin: "0",
                    mb: 2,
                  }}
                  animation="wave"
                />

                <Skeleton
                  height={"16.7rem"}
                  width={"100%"}
                  sx={{
                    transform: "scale(1)",
                    transformOrigin: "0",
                  }}
                  animation="wave"
                />
              </Box>
            </PaperBox>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            container
            spacing={isSmallScreen ? 1 : "1.7rem"}
            marginTop={isSmallScreen ? 1 : "1rem"}
          >
            {data.map((card, index) => (
              <Grid
                onClick={() => handleCardClick(card?.id, "rsvp")}
                item
                xs={6}
                sm={6}
                md={3}
                key={index}
              >
                <DashboardCard
                  noRupeeSymbol={true}
                  {...card}
                  sx={{
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#EEF2FF",
                      color: "#6366F1",
                      borderColor: "#6366F1",
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            spacing={isSmallScreen ? 1 : 2}
            marginTop={isSmallScreen ? 2 : 0}
          >
            {data1.map((card, index) => (
              <Grid
                onClick={() => handleCardClick(card?.id, "actual")}
                item
                xs={6}
                sm={6}
                md={3}
                key={index}
                style={{ cursor: "pointer" }}
              >
                <DashboardCard
                  noRupeeSymbol={true}
                  {...card}
                  sx={{
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#EEF2FF",
                      color: "#9D73F1",
                      borderColor: "#6366F1",
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            gap={isSmallScreen ? 1 : 2}
            marginTop={isSmallScreen ? 3 : 4}
          >
            <PaperBox sx={{ flex: 1, width: isSmallScreen ? "100%" : "70%" }}>
              <Box>
                <Stack direction="row" justifyContent="space-between">
                  <div>
                    <Typography variant="h4">Average Plate Count</Typography>
                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                      View and update your store details
                    </Typography>
                  </div>
                  <GreenLabel text="New" />
                </Stack>

                {!loadgraph ? (
                  <LineChart2
                    data={plateCount}
                    isSmallScreen={isSmallScreen}
                    isMediumScreen={isMediumScreen}
                  />
                ) : (
                  "Loading..."
                )}
              </Box>
            </PaperBox>

            <PaperBox
              sx={{
                flex: 1,
                width: isSmallScreen ? "100%" : "30%",
                marginTop: isSmallScreen ? 4 : 0,
              }}
            >
              <Box>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h4">Analytics</Typography>
                  <GreenLabel text="New" />
                </Stack>

                <Grid container spacing={isSmallScreen ? 1 : 2} marginTop={3}>
                  {data2.map((card, index) => (
                    <Grid
                      item
                      xs={isSmallScreen ? 12 : 0}
                      sm={isSmallScreen ? 6 : 0}
                      md={isSmallScreen ? 3 : 0}
                      key={index}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={isSmallScreen ? 1 : 2}
                        paddingLeft={isSmallScreen ? 1 : 2}
                      >
                        <Box paddingTop={isSmallScreen ? 1 : 2}>
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{ fontWeight: "bold" }}
                          >
                            {card?.amount}
                          </Typography>
                          <Typography variant="h5" component="div">
                            {card?.name}
                          </Typography>
                        </Box>
                        <Divider />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </PaperBox>
          </Box>
        </>
      )}

      {modalOpen && (
        <CustomDialog dontClose open={modalOpen} sx={{ width: "100%" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PageHeader
              secondary
              title={`Add users to ${
                selectedPlate == 1
                  ? "breakfast"
                  : selectedPlate == 2
                  ? "lunch"
                  : selectedPlate == 3
                  ? "high Tea"
                  : "dinner"
              }`}
            />
            <Close
              style={{ cursor: "pointer", fontSize: "2rem" }}
              onClick={() => setModalOpen(false)}
            />
          </Box>
          <Box style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Select
              value={selectedPlate}
              onChange={handlePlateChange}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                Select Plate Type
              </MenuItem>
              {mealData?.includes(1) && (
                <MenuItem value={1}>Breakfast RSVPs </MenuItem>
              )}
              {mealData?.includes(2) && (
                <MenuItem value={2}>Lunch RSVPs </MenuItem>
              )}
              {mealData?.includes(3) && (
                <MenuItem value={3}>High Tea RSVPs </MenuItem>
              )}
              {mealData?.includes(4) && (
                <MenuItem value={4}>Dinner RSVPs </MenuItem>
              )}
            </Select>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Text bold style={{ fontSize: "1.5rem", marginLeft: "1rem" }}>
              {selectedPlate == 1
                ? "Breakfast"
                : selectedPlate == 2
                ? "Lunch"
                : selectedPlate == 3
                ? "high Tea"
                : "Dinner"}
            </Text>
            <CustomSearchTextField
              style={{ marginBottom: "1rem" }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>

          <CustomDataGrid
            name="user-table"
            height={"490px"}
            columns={columns}
            rowId="customerAuthUID"
            url={
              process.env.REACT_APP_BACKEND_BASE_URL +
              `mess/dashboard/getUsersWithNoRSVP/${outletId}?mealTypeId=${selectedPlate}`
            }
            labelName={"Users"}
            noActions
            filterFn={getFilterData}
          />
        </CustomDialog>
      )}

      {secondModalOpen && (
        <CustomDialog dontClose open={secondModalOpen} sx={{ width: "100%" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PageHeader
              secondary
              title={`Remove users to ${
                selectedRemovePlate == 1
                  ? "breakfast"
                  : selectedRemovePlate == 2
                  ? "lunch"
                  : selectedRemovePlate == 3
                  ? "high Tea"
                  : "dinner"
              }`}
            />
            <Close
              style={{ cursor: "pointer", fontSize: "2rem" }}
              onClick={() => setSecondModalOpen(false)}
            />
          </Box>
          <Box style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Select
              value={selectedRemovePlate}
              onChange={handlePlateRemoveChange}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                Select Plate Type
              </MenuItem>
              {mealData?.includes(1) && (
                <MenuItem value={1}>Breakfast RSVPs </MenuItem>
              )}
              {mealData?.includes(2) && (
                <MenuItem value={2}>Lunch RSVPs </MenuItem>
              )}
              {mealData?.includes(3) && (
                <MenuItem value={3}>High Tea RSVPs </MenuItem>
              )}
              {mealData?.includes(4) && (
                <MenuItem value={4}>Dinner RSVPs </MenuItem>
              )}
            </Select>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Text bold style={{ fontSize: "1.5rem", marginLeft: "1rem" }}>
              {selectedRemovePlate == 1
                ? "Breakfast"
                : selectedRemovePlate == 2
                ? "Lunch"
                : selectedRemovePlate == 3
                ? "high Tea"
                : "Dinner"}
            </Text>
            <CustomSearchTextField
              style={{ marginBottom: "1rem" }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>

          <CustomDataGrid
            name="user-remove-table"
            height={"490px"}
            columns={columns1}
            rowId="customerAuthUID"
            url={
              process.env.REACT_APP_BACKEND_BASE_URL +
              `mess/dashboard/getUsersWithRSVP/${outletId}?mealTypeId=${selectedRemovePlate}`
            }
            labelName={"Users"}
            noActions
            filterFn={getFilterData}
          />
        </CustomDialog>
      )}

      {thirdModalOpen && (
        <CustomDialog dontClose open={thirdModalOpen} sx={{ width: "100%" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PageHeader
              secondary
              title={`Add users to ${
                selectedActualPlate == 1
                  ? "breakfast"
                  : selectedActualPlate == 2
                  ? "lunch"
                  : selectedActualPlate == 3
                  ? "high Tea"
                  : "dinner"
              }`}
            />
            <Close
              style={{ cursor: "pointer", fontSize: "2rem" }}
              onClick={() => setThirdModalOpen(false)}
            />
          </Box>
          <Box style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Select
              value={selectedActualPlate}
              onChange={handleActualPlateChange}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                Select Plate Type
              </MenuItem>
              {mealData?.includes(1) && (
                <MenuItem value={1}>Breakfast Plates</MenuItem>
              )}
              {mealData?.includes(2) && (
                <MenuItem value={2}>Lunch Plates </MenuItem>
              )}
              {mealData?.includes(3) && (
                <MenuItem value={3}>High Tea Plates </MenuItem>
              )}
              {mealData?.includes(4) && (
                <MenuItem value={4}>Dinner Plates </MenuItem>
              )}
            </Select>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Text bold style={{ fontSize: "1.5rem", marginLeft: "1rem" }}>
              {selectedActualPlate == 1
                ? "Breakfast"
                : selectedActualPlate == 2
                ? "Lunch"
                : selectedActualPlate == 3
                ? "high Tea"
                : "Dinner"}
            </Text>
            <CustomSearchTextField
              style={{ marginBottom: "1rem" }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>

          <CustomDataGrid
            name="user-actualadd-table"
            height={"490px"}
            columns={columns4}
            rowId="customerAuthUID"
            url={
              process.env.REACT_APP_BACKEND_BASE_URL +
              `mess/dashboard/getRSVPUsersNotServed/${outletId}?mealTypeId=${selectedActualPlate}`
            }
            labelName={"Users"}
            noActions
            filterFn={getFilterData}
          />
        </CustomDialog>
      )}

      {fourthModalOpen && (
        <CustomDialog dontClose open={fourthModalOpen} sx={{ width: "100%" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PageHeader
              secondary
              title={`Remove users to ${
                selectedRemoveActualPlate == 1
                  ? "breakfast"
                  : selectedRemoveActualPlate == 2
                  ? "lunch"
                  : selectedRemoveActualPlate == 3
                  ? "high Tea"
                  : "dinner"
              }`}
            />
            <Close
              style={{ cursor: "pointer", fontSize: "2rem" }}
              onClick={() => setFourthModalOpen(false)}
            />
          </Box>
          <Box style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Select
              value={selectedRemoveActualPlate}
              onChange={handleActualPlateRemoveChange}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                Select Plate Type
              </MenuItem>

              {mealData?.includes(1) && (
                <MenuItem value={1}>Breakfast Plates</MenuItem>
              )}
              {mealData?.includes(2) && (
                <MenuItem value={2}>Lunch Plates </MenuItem>
              )}
              {mealData?.includes(3) && (
                <MenuItem value={3}>High Tea Plates </MenuItem>
              )}
              {mealData?.includes(4) && (
                <MenuItem value={4}>Dinner Plates </MenuItem>
              )}
            </Select>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Text bold style={{ fontSize: "1.5rem", marginLeft: "1rem" }}>
              {selectedRemoveActualPlate == 1
                ? "Breakfast"
                : selectedRemoveActualPlate == 2
                ? "Lunch"
                : selectedRemoveActualPlate == 3
                ? "high Tea"
                : "Dinner"}
            </Text>
            <CustomSearchTextField
              style={{ marginBottom: "1rem" }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>

          <CustomDataGrid
            name="user-actualremove-table"
            height={"490px"}
            columns={columns5}
            rowId="customerAuthUID"
            url={
              process.env.REACT_APP_BACKEND_BASE_URL +
              `mess/dashboard/servedUsers/${outletId}?mealTypeId=${selectedRemoveActualPlate}`
            }
            labelName={"Users"}
            noActions
            filterFn={getFilterData}
          />
        </CustomDialog>
      )}

      {/* {
        qrOpen && (
          <input type="text" value={scannedData} onChange={handleDataChange}/>
        )
      } */}

      {userDataModal && (
        <CustomDialog dontClose open={userDataModal} sx={{ width: "100%" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PageHeader
              secondary
              title={`${
                mealId == 1
                  ? "Breakfast"
                  : mealId == 2
                  ? "Lunch"
                  : mealId == 3
                  ? "High Tea"
                  : "Dinner"
              } ${mealType == "rsvp" ? "RSVP" : "Actual"} Plates`}
            />
            <Close
              style={{ cursor: "pointer", fontSize: "2rem" }}
              onClick={() => setUserDataModal(false)}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <CustomSearchTextField
              style={{ marginBottom: "1rem" }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>

          <CustomDataGrid
            name="user-remove-table"
            height={"490px"}
            columns={columns3}
            rowId="customerAuthUID"
            url={
              mealType == "rsvp"
                ? `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/getUsersWithRSVP/${outletId}?mealTypeId=${mealId}`
                : `${process.env.REACT_APP_BACKEND_BASE_URL}mess/dashboard/servedUsers/${outletId}?mealTypeId=${mealId}`
            }
            labelName={"Users"}
            noActions
            filterFn={getFilterData}
          />
        </CustomDialog>
      )}
    </motion.div>
  );
};

export default MDashboard;

const LineChart2 = ({ data, isSmallScreen, isMediumScreen }) => (
  <Box
    sx={{
      height: "30rem",
      width: isSmallScreen ? "115%" : isMediumScreen ? "80vw" : "65rem",
    }}
  >
    <ResponsiveLine
      gridYLineStyle={{
        stroke: "rgba(0, 0, 0, 0.3)",
        strokeDasharray: "10 4",
      }}
      colors={["#6366F1"]}
      colorBy="index"
      data={data}
      margin={{ top: 20, right: 40, bottom: 50, left: isSmallScreen ? 20 : 70 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "0",
        stacked: true,
        reverse: false,
      }}
      yFormat=""
      curve="basis"
      axisTop={null}
      axisLeft={{
        orient: "right",
        tickSize: 0,
        tickPadding: 10,
        format: (value) => `${value}`,
        tickRotation: 0,
        legend: "",
        legendOffset: -3,
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 8,
        tickRotation: 0,
        tickValues: isSmallScreen
          ? ["1", "5", "10", "15", "20", "25", "30"] // Conditionally apply for small screens
          : undefined, // Default behavior for larger screens        legend: "",
        legendOffset: 38,
        legendPosition: "middle",
      }}
      axisRight={null}
      enableGridX={false}
      lineWidth={2}
      enablePoints={false}
      pointSize={5}
      pointColor={{ from: "color", modifiers: [] }}
      pointBorderColor={{ from: "serieColor", modifiers: [] }}
      pointLabelYOffset={-12}
      enableArea={true}
      useMesh={true}
      legends={[]}
      defs={[
        // using helpers
        // will inherit colors from current element
        linearGradientDef("gradientA", [
          { offset: 0, color: "inherit" },
          { offset: 100, color: "inherit", opacity: 0 },
        ]),
        linearGradientDef(
          "gradientB",
          [
            { offset: 0, color: "#000" },
            { offset: 100, color: "inherit" },
          ],
          {
            gradientTransform: "rotate(90 0.5 0.5)",
          }
        ),
        {
          id: "gradientC",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "#6366F1" },
            { offset: 80, color: "#E0E7FF" },
            { offset: 100, color: "white" },
          ],
        },
      ]}
      fill={[
        { match: { id: "react" }, id: "gradientA" },
        { match: (d) => d.id === "vue", id: "gradientB" },
        { match: "*", id: "gradientC" },
      ]}
    />
  </Box>
);
